<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="lp-form-offer-payment f-form"
    greedy
    @submit.prevent="onSubmit"
  >
    <div>
      <div v-text="t('LPOfferPayment.sections.paymentInfo.title')" />

      <q-separator class="q-mt-sm" />
    </div>

    <FInputCurrency
      v-model="formData.cashAmount"
      :disable="loading"
      :error-message="errors.cashAmount?.[0]"
      :error="!!errors.cashAmount"
      :label="t('LPOfferPayment.fields.cashAmount.label')"
      outlined
      :rules="[v => lteDefault(String(v).replaceAll('.', ''), formDataMerged.amount)]"
    />

    <FInputCurrency
      v-model="formData.mortgageAmount"
      :disable="loading"
      :error-message="errors.mortgageAmount?.[0]"
      :error="!!errors.mortgageAmount"
      :label="t('LPOfferPayment.fields.mortgageAmount.label')"
      outlined
      :rules="[v => lteDefault(String(v).replaceAll('.', ''), formDataMerged.amount)]"
    />

    <FInputToggle
      v-if="formData.mortgageAmount"
      v-model="formData.mortgagePreApproval"
      :disable="loading"
      :label="t('LPOfferPayment.fields.mortgagePreApproval.label')"
      :options="[
        { label: t('LPOfferPayment.fields.mortgagePreApproval.options.yes'), value: '1' },
        { label: t('LPOfferPayment.fields.mortgagePreApproval.options.no'), value: '0' },
      ]"
    />

    <FInputFile
      v-if="formData.mortgagePreApproval === '1'"
      v-model="formData.mortgagePreApprovalFile"
      clearable
      :disable="loading"
      :display-value="
        formData.mortgagePreApprovalFile?.name ||
        t('LPOfferPayment.fields.mortgagePreApprovalFile.placeholder')
      "
      :label="t('LPOfferPayment.fields.mortgagePreApprovalFile.label')"
      outlined
      :required="true"
    >
      <template #prepend>
        <q-icon color="secondary" name="attachment" />
      </template>
    </FInputFile>

    <FInput
      v-model="formData.comments"
      :disable="loading"
      :label="t('LPOffer.fields.comments.label')"
      outlined
      type="textarea"
    />

    <q-checkbox
      v-model="formData.agreedWithOfferTerms"
      color="primary"
      :disable="loading"
      keep-color
      size="md"
    >
      <template #default>
        <div class="lp-form-offer-payment__terms text-body2">
          <span v-text="t('LPOfferPayment.fields.agreedWithOfferTerms.message.one')" />

          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            @click.stop
            v-text="t('LPOfferPayment.fields.agreedWithOfferTerms.message.two')"
          />
        </div>
      </template>
    </q-checkbox>

    <q-checkbox
      v-if="formDataMerged.submitterType === 'Agent'"
      v-model="formData.signedBuyerRepresentationAgreement"
      color="primary"
      :disable="loading"
      keep-color
      size="md"
    >
      <template #default>
        <div class="lp-form-offer-payment__terms text-body2">
          <span v-text="t('LPOfferPayment.fields.signedBuyerRepresentationAgreement.label')" />
        </div>
      </template>
    </q-checkbox>

    <div ref="elContainerSignature" class="lp-form-offer-payment__signature">
      <div
        class="lp-form-offer-payment__signature-title"
        v-text="t('LPOfferPayment.sections.signature.title')"
      />

      <div
        class="lp-form-offer-payment__signature-instructions"
        v-text="t('LPOfferPayment.sections.signature.subtitle')"
      />

      <div class="lp-form-offer-payment__signature-container" @touchmove.prevent>
        <canvas
          ref="elCanvas"
          class="lp-form-offer-payment__signature-canvas"
          style="touch-action: none; user-select: none"
        />
      </div>

      <q-btn
        class="lp-form-offer-payment__signature-buttons"
        color="primary"
        dense
        :disable="loading"
        flat
        icon="refreshHalfArrow"
        :label="t('LPOffer.btn.clearSignature')"
        no-caps
        outline
        unelevated
        @click="clearSignature"
      />

      <div class="f-input__label--error" v-text="signatureErrorMsg" />
    </div>

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsPrev" @click="onPrev" />
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import SignaturePad from 'signature_pad';
import { computed, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputCurrency from '@/components/Form/input/FInputCurrency.vue';
import FInputFile from '@/components/Form/input/FInputFile.vue';
import FInputToggle from '@/components/Form/input/FInputToggle.vue';
import type {
  OfferFormDvg,
  OfferFormPayment,
} from '@/components/ListingPage/Form/LPFormOfferDvg/LPOfferDvg.vue';
import { useApiListing } from '@/composables/api/listing';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import useFileHandler from '@/composables/useFileHandler';
import { useUtmSource } from '@/composables/useUtmSource';
import translations from '@/i18n/translations/components/formSteps.json';
import type { ApiError } from '@/types/api';
import type { FormId } from '@/types/formStepsFactory';

const { storeOffer } = useApiListing();
const { getToken } = useCaptcha();
const { base64ToBlob } = useFileHandler();
const { resolveUtmSource } = useUtmSource();

const { locale, t } = useI18n(translations);

const emit = defineEmits<{
  (e: 'prev', p?: typeof formData.value): void;
  (e: 'next'): void;
}>();

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next: OfferFormDvg;
}>();

const signatureErrorMsg = ref('');

const errors = ref<Partial<Record<keyof typeof formData.value, string[]>>>({});

const elCanvas = useTemplateRef('elCanvas');
const signaturePad = ref<SignaturePad | null>(null);

const { elQForm, lte } = useFormInputRules();

const lteDefault = lte();

const loading = ref(false);

const elContainerSignature = useTemplateRef('elContainerSignature');

const formData = ref<OfferFormPayment>({
  comments: props.next.comments || '',
  mortgagePreApproval: props.next.mortgagePreApproval || null,
  mortgagePreApprovalFile: props.next.mortgagePreApprovalFile || null,
  signatureFile: props.next.signatureFile || null,
  paymentOption: props.next.paymentOption || null,
  cashAmount: props.next.cashAmount || null,
  mortgageAmount: props.next.mortgageAmount || null,
  agreedWithOfferTerms: false,
  signedBuyerRepresentationAgreement: false,
  r_token: '',
  utmSource: resolveUtmSource(),
});

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const formDataMerged = computed(() => ({
  ...props.next,
  ...formData.value,
}));

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  disable: !formData.value.agreedWithOfferTerms,
  form: `f-${props.formId}`,
  label: t('LPOffer.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const btnPropsPrev = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value,
  label: t('LPOffer.btn.back'),
  noCaps: true,
  outline: true,
  padding: '0.8rem',
  unelevated: true,
}));

const onPrev = () => {
  emit('prev', formDataMerged.value);
};

const clearSignature = () => {
  signaturePad.value?.clear();
};

watch(
  () => formData.value.mortgagePreApproval,
  v => {
    if (v === '1') return;

    formData.value.mortgagePreApprovalFile = null;
  }
);

const onSubmit = async () => {
  if (!signaturePad.value || signaturePad.value.isEmpty()) {
    signatureErrorMsg.value =
      locale.value === 'en' ? 'The field is required' : 'Το πεδίο είναι απαραίτητο';

    elContainerSignature.value?.scrollIntoView({ behavior: 'smooth' });

    return;
  }

  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;

  errors.value = {};

  const rToken = await getToken('submitOffer');
  formData.value.r_token = rToken;

  const data = new FormData();

  // Recursive function to flatten and append FormData
  const appendToFormData = (object: any, parentKey: string = '') => {
    if (Array.isArray(object)) {
      object.forEach((value, index) => {
        appendToFormData(value, `${parentKey}[${index}]`);
      });
    } else if (object instanceof Blob) {
      data.append(parentKey, object);
    } else if (typeof object === 'object' && object !== null) {
      // recursively
      Object.entries(object).forEach(([key, value]) => {
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        appendToFormData(value, newKey);
      });
    } else if (typeof object === 'boolean') {
      data.append(parentKey, object ? '1' : '0');
    } else {
      data.append(parentKey, object instanceof Blob ? object : object?.toString() || '');
    }
  };

  const payload = {
    ...formDataMerged.value,
    signature: base64ToBlob(signaturePad.value.toDataURL(), 'image/png'),
  };

  // flatten
  appendToFormData(payload);

  storeOffer(data)
    .then(() => {
      emit('next');
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 422:
            errors.value = response.data.errors;
            break;
          default:
            console.error('Unexpected error:', error);
            break;
        }
      } else {
        // Handle network or unexpected errors
        console.error('Network error:', error);
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const initCanvas = () => {
  if (!elCanvas.value) return;

  // Get the device pixel ratio
  const ratio = Math.max(window.devicePixelRatio || 1, 1);

  elCanvas.value.width = elCanvas.value.offsetWidth * ratio;
  elCanvas.value.height = elCanvas.value.offsetHeight * ratio;
  elCanvas.value?.getContext('2d')?.scale(ratio, ratio);

  // signature options
  signaturePad.value = new SignaturePad(elCanvas.value, {
    maxWidth: 3,
    minWidth: 1,
  });
};

onMounted(() => {
  initCanvas();
});
</script>

<style lang="scss">
.lp-form-offer-payment {
  .lp-form-offer-payment__terms a {
    margin-left: 0.25rem;
    color: $primary;
    text-decoration: none;
  }

  .q-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .q-checkbox__label {
    padding: 0.5rem;
  }

  .lp-form-offer-payment__signature {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    background: #fbfbfb;
    border: 1px solid $util-2;
    border-radius: 8px;
  }

  .lp-form-offer-payment__signature-title {
    font-size: 0.875rem;
    font-weight: 700;
    color: $secondary;
  }

  .lp-form-offer-payment__signature-instructions {
    margin-bottom: 10px;
    font-size: 0.75rem;
    font-weight: 600;
    color: $accent;
  }

  .lp-form-offer-payment__signature-container {
    position: relative;
    width: 100%;
    max-width: 1280px;
    max-height: 300px;
    aspect-ratio: 16 / 6;
    overflow: hidden;
    background: white;
    border: 2px dashed #ddd;
    border-radius: 8px;

    &.is-empty {
      border: 2px dashed $negative;
      border-color: $negative-1;
    }
  }

  .lp-form-offer-payment__signature-canvas {
    display: block;
    width: 100%;
    height: 100%;
  }

  .lp-form-offer-payment__signature-buttons {
    display: flex;
    align-self: flex-end;
    margin-top: 5px;
  }

  .lp-form-offer-payment__signature-buttons .q-btn {
    padding: 8px 16px;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 5px;
  }
}
</style>
