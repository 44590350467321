<template>
  <DialogForm
    v-bind="qDialogProps"
    :id="formIdLocal"
    ref="elDialogForm"
    :class="`dialog-form-stepper journey--${factoryId}`"
  >
    <template #header>
      <div>
        <div v-if="avatarEnabledLocal" class="row justify-center">
          <GlobalAvatar v-bind="stepCurrent?.avatar?.props" />
        </div>

        <div v-if="titleLocal || subtitleLocal" class="dialog-form-stepper__header">
          <div v-if="titleLocal" class="dialog-form-stepper__header-title" v-text="titleLocal" />
          <div
            v-if="subtitleLocal"
            class="dialog-form-stepper__header-subtitle"
            v-text="subtitleLocal"
          />
        </div>
      </div>
    </template>

    <template #default="{ isMounted }">
      <div v-if="isMounted && formIdLocal">
        <Transition :name="transitionName">
          <component
            v-bind="componentPropsLocal"
            :is="componentLocal"
            ref="elFormStep"
            :form-id="formIdLocal"
            class="dialog-form-stepper__body"
            is-dialog
            :next="next"
            :prev="prev"
            @next="onNextHandler"
            @prev="onPrevHandler"
            @update-next="onUpdateNext"
          />
        </Transition>
      </div>
    </template>
  </DialogForm>
</template>

<script setup lang="ts">
import { type QDialogProps, Screen } from 'quasar';
import { computed, markRaw, nextTick, useTemplateRef, watch, type WatchStopHandle } from 'vue';

import DialogForm from '@/components/Dialog/DialogForm.vue';
import GlobalAvatar from '@/components/Global/GlobalAvatar.vue';
import { useDialogFormStepper } from '@/composables/dialogFormStepper';
import type { DialogFormStepperProps } from '@/types/dialogForm';
import type { Next } from '@/types/formStepsFactory';

interface Props extends QDialogProps, DialogFormStepperProps {}

const props = withDefaults(defineProps<Props>(), {
  maximized: Screen.lt.md,
});

const modelValue = defineModel<boolean>('modelValue', { required: true });

const state = useDialogFormStepper(props);

const {
  avatarEnabledLocal,
  busEmit,
  componentLocal,
  componentPropsLocal,
  elFormStep,
  formIdLocal,
  isFormEnabled,
  next,
  onNext,
  onPrev,
  onUpdateNext,
  prev,
  stepCurrent,
  stepIndex,
  subtitleLocal,
  titleLocal,
  transitionName,
} = state;

const elDialogForm = useTemplateRef<InstanceType<typeof DialogForm>>('elDialogForm');

const qDialogProps = computed(() => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _props: Omit<QDialogProps, 'modelValue'> & Partial<DialogFormStepperProps> = {
    ...props,
  };

  delete _props.qCardProps;
  delete _props.steps;
  delete _props.factoryId;

  return { ..._props, maximized: Screen.lt.md };
});

const onNextHandler = (event: Next) =>
  stepCurrent.value?.onNextCallback
    ? stepCurrent.value?.onNextCallback(event, state)
    : onNext(event);

const onPrevHandler = (event: Next) =>
  stepCurrent.value?.onPrevCallback
    ? stepCurrent.value?.onPrevCallback(event, state)
    : onPrev(event);

const watchHandlers = markRaw<WatchStopHandle[]>([]);

const clearState = () => {
  watchHandlers.forEach(wH => wH());
  watchHandlers.splice(0);

  next.value = {};
  prev.value = {};
  stepIndex.value = 0;
};

const watchFormCurrentEnabledLocal = () =>
  watch(
    modelValue,
    v => {
      if (v && !isFormEnabled(stepCurrent.value)) {
        onNext({});
      }
    },
    { immediate: true, once: true }
  );

const watchFormCurrent = () =>
  watch(stepCurrent, v => {
    if (!v) {
      modelValue.value = false;
    }
  });

const init = () => {
  const wFCHandler = watchFormCurrent();
  watchFormCurrentEnabledLocal();

  watchHandlers.push(wFCHandler);
};

watch(modelValue, v => {
  if (v) {
    init();
  } else {
    busEmit(stepIndex.value !== props.steps.length - 1 ? 'abort' : 'complete');

    nextTick(() => {
      clearState();
    });
  }
});
</script>

<style lang="scss">
@use 'sass:map';

.dialog-form-stepper__header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
}

.dialog-form-stepper__header-title {
  margin: 0 1rem;
  font-size: 1.75rem;
  font-weight: 800;
  color: $secondary;
}

.dialog-form-stepper__header-subtitle {
  font-size: 0.875rem;
  font-weight: 600;
  color: $accent;
}
</style>
