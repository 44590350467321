<template>
  <div class="lp-open-day-slot-picker">
    <div class="lp-cta-widget-open-day__tagline" v-text="t('LPOpenDay.extra.header')" />

    <q-carousel
      v-model="slide"
      animated
      arrows
      control-color="secondary"
      :height="$q.screen.gt.sm ? 'auto' : 'inherit'"
      swipeable
    >
      <q-carousel-slide
        v-for="(openDays, i) in chunkedOpenDates"
        :key="i"
        :name="i"
        :class="{ 'row justify-center': $q.screen.lt.md }"
      >
        <q-btn
          v-for="(openDay, j) in openDays"
          :key="j"
          :class="{
            'lp-cta-widget-open-day__btn': true,
            'lp-cta-widget-open-day__btn--selected':
              j === selectedOpenDayIndex &&
              toDate(openDay.date, false, 'YYYY-MM-DD') === modelValue.date,
          }"
          stack
          no-caps
          no-wrap
          unelevated
          @click="onClickBtnOpenDay(openDay, j)"
        >
          <div
            class="lp-cta-widget-open__date-text"
            v-text="toDate(openDay.date, false, 'dddd, MMM D', locale)"
          />
          <div
            class="lp-cta-widget-open__time-slot-text"
            v-text="getTimeSlot(openDay.timeFrom, openDay.timeTo)"
          />
        </q-btn>
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script setup lang="ts">
import chunk from 'lodash/chunk';
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import tFormSteps from '@/i18n/translations/components/formSteps.json';
import { useListingStore } from '@/store/modules/listing';
import type { OpenDayConfig } from '@/types/api/listing';
import { toDate } from '@/utils/time';

const modelValue = defineModel<{
  date: string;
  timeSlot: string;
}>('modelValue', { required: true });

const { locale, t } = useI18n(tFormSteps);
const { listingConfig } = storeToRefs(useListingStore());
const selectedOpenDayIndex = ref(-1);

const slide = ref(0);

const getTimeMeridian = (time: string) => {
  const makeTime = time.split(':');
  return `${makeTime[0]}:${makeTime[1]} ${makeTime[0] >= '12' ? t('LPOpenDay.extra.pm') : t('LPOpenDay.extra.am')}`;
};

const getTimeSlot = (from: string, to: string) =>
  `${getTimeMeridian(from)} - ${getTimeMeridian(to)}`;

const chunkedOpenDates = computed(() => {
  let size = 2;
  switch (Screen.name) {
    case 'xs':
      size = 6;
      break;
    case 'sm':
      size = 4;
      break;
    default:
      size = 2;
      break;
  }

  return chunk(listingConfig.value?.openDays.options, size);
});

const onClickBtnOpenDay = (openDay: OpenDayConfig, j: number) => {
  const date = toDate(openDay.date, false, 'YYYY-MM-DD');
  if (!date) return;

  selectedOpenDayIndex.value = j;

  modelValue.value.date = date;
  modelValue.value.timeSlot = [openDay.timeFrom, openDay.timeTo].join(' - ');
};
</script>

<style lang="scss">
@use 'sass:map';

.lp-open-day-slot-picker {
  .q-carousel {
    .q-carousel__control {
      .q-btn {
        width: 1rem;
        height: 1rem;
      }

      .q-icon svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    .q-carousel__next-arrow--horizontal {
      margin-right: -1rem;
    }

    .q-carousel__prev-arrow--horizontal {
      margin-left: -1rem;
    }

    .q-carousel__slide {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;
    }
  }
}

.lp-cta-widget-open-day__btn {
  flex: 1;
  padding: 0.75rem 1rem;
  color: $secondary;
  border: 0.0625rem solid $util-1;
  border-radius: map.get($radius-sizes, 'sm');

  &:hover {
    color: white;
    background: $primary;
  }
}

.lp-cta-widget-open-day__tagline {
  font-size: 0.875rem;
  font-weight: 600;
  color: $accent;
}

.lp-cta-widget-open__date-text {
  font-size: 1rem;
  font-weight: 800;
}

.lp-cta-widget-open__time-slot-text {
  align-items: center;
  font-weight: 600;
}

.lp-cta-widget-open-day__btn--selected {
  color: white;
  background: $primary;
  border-color: $primary;
}
</style>
