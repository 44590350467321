<template>
  <div class="lp-offer-additional">
    <q-list v-if="modelValue.buyers && modelValue.buyers.length">
      <div v-for="(buyer, index) in modelValue.buyers" :key="index">
        <q-expansion-item v-model="expand[index]" default-opened dense-toggle expand-separator>
          <template #header>
            <div class="lp-offer-additional__buyer-header">
              <span
                class="lp-offer-additional__buyer-header-text"
                v-text="t('LPOffer.extra.buyersInfo', { buyerIndex: index + 1 })"
              />
              <q-btn
                v-if="modelValue.buyers.length > 1 && index > 0"
                class="lp-offer-additional__buyer-remove-btn"
                dense
                icon="trashCan"
                :label="t('LPOffer.btn.remove')"
                no-caps
                unelevated
                @click.stop="onRemoveBuyer(index)"
              />
            </div>
          </template>

          <q-card flat>
            <q-card-section>
              <div class="lp-offer-additional__buyer-section-title">
                <div v-text="t('LPOffer.sections.personalInfo.title')" />
                <q-separator />
              </div>

              <div class="f-form_grid_columns">
                <FInput
                  v-model="buyer.firstName"
                  :error="!!errors?.[`buyers.${index}.personalInfo.firstName`]"
                  :error-message="errors?.[`buyers.${index}.personalInfo.firstName`]?.[0]"
                  :label="t('LPOffer.fields.firstName.label')"
                  outlined
                  :rules="[requiredDefault]"
                />
                <FInput
                  v-model="buyer.lastName"
                  :error="!!errors?.[`buyers.${index}.personalInfo.lastName`]"
                  :error-message="errors?.[`buyers.${index}.personalInfo.lastName`]?.[0]"
                  :label="t('LPOffer.fields.lastName.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model="buyer.fathersName"
                  :error="!!errors?.[`buyers.${index}.personalInfo.fathersName`]"
                  :error-message="errors?.[`buyers.${index}.personalInfo.fathersName`]?.[0]"
                  :label="t('LPOffer.fields.fathersName.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model="buyer.mothersName"
                  :error="!!errors?.[`buyers.${index}.personalInfo.mothersName`]"
                  :error-message="errors?.[`buyers.${index}.personalInfo.mothersName`]?.[0]"
                  :label="t('LPOffer.fields.mothersName.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInputDatePicker
                  v-model="buyer.dateOfBirth"
                  :error="!!errors?.[`buyers.${index}.personalInfo.dateOfBirth`]"
                  :error-message="errors?.[`buyers.${index}.personalInfo.dateOfBirth`]?.[0]"
                  :label="t('LPOffer.fields.dateOfBirth.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model="buyer.placeOfBirth"
                  :error="!!errors?.[`buyers.${index}.placeOfBirth`]"
                  :error-message="errors?.[`buyers.${index}.personalInfo.placeOfBirth`]?.[0]"
                  :label="t('LPOffer.fields.placeOfBirth.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInputPhone
                  v-model="buyer.mobileNumber"
                  :error="!!errors?.[`buyers.${index}.mobileNumber`]"
                  :error-message="errors?.[`buyers.${index}.mobileNumber`]?.[0]"
                  :label="t('LPOffer.fields.mobileNumber.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model="buyer.occupation"
                  :error="!!errors?.[`buyers.${index}.occupation`]"
                  :error-message="errors?.[`buyers.${index}.occupation`]?.[0]"
                  :label="t('LPOffer.fields.occupation.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model="buyer.idNumber"
                  :error="!!errors?.[`buyers.${index}.idNumber`]"
                  :error-message="errors?.[`buyers.${index}.idNumber`]?.[0]"
                  :label="t('LPOffer.fields.idNumber.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model="buyer.vatNumber"
                  :error="!!errors?.[`buyers.${index}.vatNumber`]"
                  :error-message="errors?.[`buyers.${index}.vatNumber`]?.[0]"
                  :label="t('LPOffer.fields.vatNumber.label')"
                  outlined
                  :rules="[requiredDefault]"
                />
              </div>
            </q-card-section>

            <q-card-section>
              <div class="lp-offer-additional__buyer-section-title">
                <div v-text="t('LPOffer.sections.residentialInfo.title')" />
                <q-separator />
              </div>

              <div class="f-form_grid_columns">
                <FInput
                  v-model="buyer.country"
                  :error="!!errors?.[`buyers.${index}.country`]"
                  :error-message="errors?.[`buyers.${index}.country`]?.[0]"
                  :label="t('LPOffer.fields.country.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model="buyer.city"
                  :error="!!errors?.[`buyers.${index}.city`]"
                  :error-message="errors?.[`buyers.${index}.city`]?.[0]"
                  :label="t('LPOffer.fields.city.label')"
                  outlined
                  :rules="[requiredDefault]"
                />
              </div>

              <FInput
                v-model="buyer.street"
                :error="!!errors?.[`buyers.${index}.street`]"
                :error-message="errors?.[`buyers.${index}.street`]?.[0]"
                :label="t('LPOffer.fields.street.label')"
                outlined
                :rules="[requiredDefault]"
              />
              <div class="f-form_grid_columns">
                <FInput
                  v-model="buyer.number"
                  :error="!!errors?.[`buyers.${index}.number`]"
                  :error-message="errors?.[`buyers.${index}.number`]?.[0]"
                  :label="t('LPOffer.fields.number.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model="buyer.postalCode"
                  :error="!!errors?.[`buyers.${index}.postalCode`]"
                  :error-message="errors?.[`buyers.${index}.postalCode`]?.[0]"
                  :label="t('LPOffer.fields.postalCode.label')"
                  outlined
                  :rules="[requiredDefault]"
                />

                <FInput
                  v-model.number="buyer.ownershipPercentage"
                  :error="!!errors?.[`buyers.${index}.ownershipPercentage`]"
                  :error-message="errors?.[`buyers.${index}.ownershipPercentage`]?.[0]"
                  :label="t('LPOffer.fields.ownershipPercentage.label')"
                  :max="100"
                  :min="0"
                  outlined
                  :rules="[requiredDefault, v => gtDefault(v, 1), v => lteDefault(v, 100)]"
                  type="number"
                >
                  <template #append>
                    <span v-text="'%'" />
                  </template>
                </FInput>
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>

        <q-separator
          v-if="index < modelValue.buyers.length - 1 || modelValue.buyers.length !== 1"
        />
      </div>
    </q-list>

    <DialogForm
      id="remove-buyer-dialog"
      v-model="dialogRemoveBuyer"
      :maximized="false"
      :q-icon-props="{
        color: 'warning',
        left: true,
        name: 'warning',
        size: '1.75rem',
      }"
      :title="t('LPOffer.extra.removeBuyerDialog.title')"
    >
      <div>
        <div class="row items-center">
          <span
            class="text-body2 text-accent q-mt-sm"
            v-text="t('LPOffer.extra.removeBuyerDialog.messageOne')"
          />

          <div
            class="q-mt-sm text-body2 text-accent"
            v-text="t('LPOffer.extra.removeBuyerDialog.messageTwo')"
          />
        </div>

        <Teleport defer :to="removeBuyerTeleportTarget">
          <q-btn v-bind="btnPropsNext" @click="onDialogCancelRemoveBuyer" />

          <q-btn v-bind="btnPropsPrevious" @click="onDialogRemoveBuyer" />
        </Teleport>
      </div>
    </DialogForm>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import DialogForm from '@/components/Dialog/DialogForm.vue';
import FInput from '@/components/Form/input/FInput.vue';
import FInputDatePicker from '@/components/Form/input/FInputDatePicker.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import type { OfferFormIndividual } from '@/components/ListingPage/Form/LPFormOfferDvg/LPOfferDvg.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import translations from '@/i18n/translations/components/formSteps.json';
import type { Next } from '@/types/formStepsFactory';

defineProps<{
  next?: Next;
  errors: Partial<Record<keyof FormData | string, string[]>>;
}>();

const modelValue = defineModel<OfferFormIndividual>('modelValue', { required: true });
const addBuyer = defineModel<boolean>('addBuyer', { required: true, default: false });

const { t } = useI18n(translations);
const { gt, lte, required } = useFormInputRules();

const requiredDefault = required();
const gtDefault = gt();
const lteDefault = lte();

const removeBuyerTeleportTarget = `#remove-buyer-dialog .dialog-form--actions`;

const dialogRemoveBuyer = ref(false);
const removeBuyerIndex = ref(-1);

const expand = ref<boolean[]>([true]);

const btnPropsNext = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  label: t('LPOffer.btn.cancel'),
  noCaps: true,
  outline: true,
  padding: '0.8rem',
  unelevated: true,
}));

const btnPropsPrevious = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  label: t('LPOffer.btn.remove'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  unelevated: true,
}));

watch(
  () => modelValue.value.buyers?.length,
  (newValue, oldValue) => {
    if (!oldValue || !newValue) return;

    if (newValue === 1) {
      expand.value[0] = true;
      return;
    }

    if (oldValue > newValue) return;

    expand.value = modelValue.value.buyers!.map((_, index, array) => index === array.length - 1);
  }
);

const onAddBuyer = () => {
  modelValue.value.buyers.push({
    vatNumber: '',
    idNumber: '',
    mobileNumber: '',
    occupation: '',

    firstName: '',
    lastName: '',
    fathersName: '',
    mothersName: '',
    placeOfBirth: '',
    dateOfBirth: '',
    country: '',
    city: '',
    street: '',
    number: '',
    postalCode: '',
    ownershipPercentage: null,
  });
};

const onDialogCancelRemoveBuyer = () => {
  dialogRemoveBuyer.value = false;
  removeBuyerIndex.value = -1;
};

const onDialogRemoveBuyer = () => {
  if (modelValue.value.buyers && modelValue.value.buyers?.length > 1) {
    modelValue.value.buyers.splice(removeBuyerIndex.value, 1);
  }

  dialogRemoveBuyer.value = false;
  removeBuyerIndex.value = -1;
};

const onRemoveBuyer = (index: number) => {
  dialogRemoveBuyer.value = true;
  removeBuyerIndex.value = index;
};

watch(
  () => addBuyer.value,
  () => {
    if (addBuyer.value) {
      onAddBuyer();
      addBuyer.value = false;
    }
  }
);
</script>

<style lang="scss">
.lp-offer-additional {
  .q-card__section {
    padding: 0;
  }

  .q-item {
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
  }

  .q-expansion-item__toggle-icon {
    color: $primary;
  }

  .q-field__append {
    font-size: 0.875rem;
    color: $secondary;
  }
}

.lp-offer-additional__buyer-header {
  display: flex;
  gap: 0 2rem;
  align-items: center;
  justify-content: space-between;
  font-size: 2rem;
}

.lp-offer-additional__buyer-header-text {
  font-size: 1rem;
  font-weight: 700;
  color: $primary;
}

.lp-offer-additional__buyer-remove-btn {
  font-size: 0.75rem;
  font-weight: 700;
  color: $negative;
}

.lp-offer-additional__buyer-add-btn {
  width: 100%;

  .q-btn {
    width: 100%;
  }
}

.lp-offer-additional__buyer-section-title {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  color: $secondary;

  .q-separator {
    margin: 0.5rem 0 1rem;
  }
}
</style>
