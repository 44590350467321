<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="f-form f-form-offer"
    @submit.prevent="onSubmit"
  >
    <div v-if="listingPrice" class="f-form-offer__listing-price">
      <q-icon name="houseWithMoneySign" size="1rem" color="primary" class="q-mr-sm" />
      <span
        class="f-form-offer__listing-price-text"
        v-text="`${t('LPOffer.extra.listingPrice')}:`"
      />
      <span class="f-form-offer__listing-price-amount" v-text="formatPrice(listingPrice)" />
    </div>

    <FInputCurrency
      v-model="formData.amount"
      :label="t('LPOffer.fields.amount.label')"
      outlined
      :required="true"
      :rules="[requiredDefault]"
    />

    <FInputToggle
      v-model="formData.submitterType"
      class="f-form-offer__checkbox-participation"
      :label="t('LPOffer.fields.submitterType.label')"
      :options="submitterTypeOptions"
      :required="true"
      :rules="[requiredDefault]"
    />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <div class="f-form-offer__actions">
        <q-btn v-bind="btnPropsSubmit" />
      </div>
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import FInputCurrency from '@/components/Form/input/FInputCurrency.vue';
import FInputToggle from '@/components/Form/input/FInputToggle.vue';
import type { OfferFormDvg } from '@/components/ListingPage/Form/LPFormOfferDvg/LPOfferDvg.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import { useTheme } from '@/composables/theme';
import translations from '@/i18n/translations/components/formSteps.json';
import { useListingStore } from '@/store/modules/listing';
import type { FormId } from '@/types/formStepsFactory';

type FormData = Pick<OfferFormDvg, 'amount' | 'listingId' | 'submitterType'>;

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next?: FormData;
  prev?: FormData;
}>();

const emit = defineEmits<{
  (e: 'next', p: FormData): void;
}>();

const { listing } = storeToRefs(useListingStore());

const { t } = useI18n(translations);

const { formatPrice } = useTheme();
const { required } = useFormInputRules();

const requiredDefault = required();

const listingPrice = computed(() => listing.value?.price || 0);

const formData = ref<FormData>({
  amount: props.next?.amount || listingPrice.value,
  listingId: listing.value!.id,
  submitterType: props.next?.submitterType || null,
});

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const submitterTypeOptions = computed(() => [
  {
    label: t('LPOffer.fields.submitterType.options.agent'),
    value: 'Agent',
  },
  {
    label: t('LPOffer.fields.submitterType.options.individual'),
    value: 'Individual',
  },
]);

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl full-width',
  color: 'primary',
  form: `f-${props.formId}`,
  label: t('LPOffer.btn.continue'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const onSubmit = () => {
  emit('next', { ...props.next, ...props.prev, ...formData.value });
};
</script>

<style lang="scss">
@use 'sass:map';

.f-form-offer__listing-price {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0;
  background: $primary-3;
  border-radius: map.get($radius-sizes, 'sm');
}

.f-form-offer__listing-price-text {
  font-size: 0.875rem;
  font-weight: 600;
  color: $secondary;
}

.f-form-offer__listing-price-amount {
  font-size: 1.125rem;
  font-weight: 800;
  color: $primary;
}

.f-form-offer__actions {
  width: 100%;
}

.f-form-offer__checkbox-participation {
  align-items: center;

  .q-checkbox__label {
    font-size: 0.875rem;
    font-weight: 800;
    color: #000;
  }
}
</style>
