import dayjs from 'dayjs';
import type { QForm } from 'quasar';
import { shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

import tFormInputRules from '@/i18n/translations/components/formInputRules.json';

/* eslint-disable @typescript-eslint/default-param-last */
export type InputValue = any;
export type Rule = (v: InputValue, ...opts: Array<InputValue>) => boolean | string;
export type RuleConstructor = (errorMsg?: string) => Rule;

const isEmpty = (v: InputValue) => ['', null, undefined].includes(v);

/**
 * Ported from the LMS custom library.
 *
 * @see https://bitbucket.org/_devshell/lms-spa/src/master/lib/prosperty/composables/PInputRules.ts
 */
export const useFormInputRules = () => {
  const { t } = useI18n({ ...tFormInputRules, useScope: 'global' });

  const date: RuleConstructor =
    errorMsg =>
    (v = '', format = 'DD/MM/YYYY') =>
      (!v || dayjs(v, format, true).isValid() || errorMsg) ?? t('date');

  const digitsBetween: RuleConstructor =
    errorMsg =>
    (v = '', min: any, max: any) =>
      (!v || (String(v).length >= Number(min) && String(v).length <= Number(max)) || errorMsg) ??
      t('digitsBetween', { min, max });

  const elQForm = shallowRef<InstanceType<typeof QForm> | null>(null);

  const email: RuleConstructor =
    errorMsg =>
    (v = '') =>
      (isEmpty(v) || /^\w+([.-.+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(`${v}`) || errorMsg) ??
      t('email');

  const eq: RuleConstructor =
    (errorMsg?: string) =>
    (v = '', eqValue: any) =>
      (isEmpty(v) || isEmpty(eqValue) || Number(v) === Number(eqValue) || errorMsg) ??
      t('eq', { eq: eqValue });

  const gt: RuleConstructor =
    (errorMsg?: string) =>
    (v = '', gtValue: any) =>
      (isEmpty(v) || isEmpty(gtValue) || Number(v) > Number(gtValue) || errorMsg) ??
      t('gt', { gt: gtValue });

  const gte: RuleConstructor =
    (errorMsg?: string) =>
    (v = '', gteValue: any) =>
      (isEmpty(v) || isEmpty(gteValue) || Number(v) >= Number(gteValue) || errorMsg) ??
      t('gte', { gte: gteValue });

  const lt: RuleConstructor =
    (errorMsg?: string) =>
    (v = '', ltValue: any) =>
      (isEmpty(v) || isEmpty(ltValue) || Number(v) < Number(ltValue) || errorMsg) ??
      t('lt', { lt: ltValue });

  const lte: RuleConstructor =
    (errorMsg?: string) =>
    (v = '', lteValue: any) =>
      (isEmpty(v) || isEmpty(lteValue) || Number(v) <= Number(lteValue) || errorMsg) ??
      t('lte', { lte: lteValue });

  const numeric: RuleConstructor =
    (errorMsg = t('numeric')) =>
    (v = '') =>
      isEmpty(v) || typeof v === 'number' || !Number.isNaN(Number(v)) || errorMsg;

  const phone: RuleConstructor =
    (errorMsg = t('phone')) =>
    (v: boolean) =>
      v || errorMsg;

  const required: RuleConstructor =
    (errorMsg = t('required')) =>
    (v = '') =>
      (Array.isArray(v) ? v.length > 0 : !isEmpty(v)) || errorMsg;

  return {
    date,
    digitsBetween,
    elQForm,
    email,
    eq,
    gt,
    gte,
    lt,
    lte,
    numeric,
    phone,
    required,
  };
};
